<template>
  <div class="caseFailure-caseImg">
    <GlobalInfoBar title="首页广告管理" content="用于展示在门店端小程序首页轮播广告模块，可根据不同角色展示不同广告" />
    <div class="case">
      <div class="case-title">
        <img class="case-title_icon" src="@/assets/images/icon_hsfl_.png" alt />
        <div class="case-title_max">广告列表</div>
      </div>
      <div class="button_top">
        <div>
          <el-button type="primary" icon="el-icon-plus" @click="setAddInfo('add')">添加广告</el-button>
        </div>
      </div>
      <div style="padding: 0 20px">
        <GlobalTable class="sample-image" ref="GlobalTable" v-loading="tableLoading" :page-size="page.pageSize"
          :columns="tableColumns" :data="tableData" :currentPage="page.pageNum" :total="page.total"
          @handleCurrentChange="handleCurrentChange(1)">
          <el-table-column label="封面图" slot="coverImage" align="center">
            <template slot-scope="{ row }">
              <img :src="row.coverImage" style="width: 70px;height:70px" alt="" @click="seeSampleImage(row)" />
            </template>
          </el-table-column>
          <el-table-column label="是否可跳转" slot="isJump" align="center">
            <template slot-scope="{ row }">
              {{ row.isJump ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column label="创建人、子账号展示" slot="isFounder" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.isFounder" active-text="开" inactive-text="关" inactive-color="#b6b9d0"
                @change="(e) => switchChange(e, row, '00')">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="总监展示" slot="isSenior" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.isSenior" active-text="开" inactive-text="关" inactive-color="#b6b9d0"
                @change="(e) => switchChange(e, row, '01')">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="店长店员展示" slot="isStaff" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.isStaff" active-text="开" inactive-text="关" inactive-color="#b6b9d0"
                @change="(e) => switchChange(e, row, '02')">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="默认排序" slot="sort" align="center">
            <template slot-scope="{ row }">
              <el-input-number v-model="row.sort" @change="handleChange(row)" size="mini" :min="1"
                label="排序"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="操作" slot="operation" align="center">
            <template slot-scope="{ row }">
              <el-button size="mini" type="warning" round @click="setAddInfo('edit', row)">编辑</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </div>
    <!--添加/编辑广告弹框-->
    <el-dialog class="set-img" :title="imageDialogTitle" :visible.sync="imageDialogVisible" :close-on-click-modal="false"
      width="800px" @closed="imageHandleClose">
      <div>
        <div class="command" style="margin-bottom: 10px;font-size:14px;color:#333333;width: 540px;">
          <div style="width:90px;font-weight: 700;"> <span style="color:#ff687b;">*</span>广告名称：</div>
          <el-input v-model.trim="addName" maxlength="15" placeholder="请输入正常选项描述，最多15个字" type="text" size="small"
            style="width:410px;" />
        </div>
        <div style="margin-bottom: 10px;font-size:14px;color:#333333;font-weight: 700;">
          <span style="color:#ff687b;">*</span>广告封面图
        </div>
        <div class="demo-image__preview">
          <ElImgUpload key="sampleImage" :isBigImg="true" :exist-image="sampleImage" @handleChange="sampleHandleChange" />
        </div>
        <!-- 是否可跳转 -->
        <div style="margin: 10px 0;font-size:14px;color:#333333;font-weight: 700;">
          <span style="color:#ff687b;">*</span><span style="margin-right: 20px;">是否可跳转</span>
          <template>
            <el-radio v-model="isJump" label="1">可跳转</el-radio>
            <el-radio v-model="isJump" label="2">不可跳转</el-radio>
          </template>
        </div>
        <!-- 内容 富文本-->
        <div v-if="isJump === '1'">
          <div style="margin-bottom: 10px;font-size:14px;color:#333333;font-weight: 700;">
            <span style="color:#ff687b;">*</span>上传内容
          </div>
          <div class="flex-box">
            <j-editor @handleEditContent="handleEditContent"
              @handleSettingEditContent="handleSettingEditContent"  :content="content"></j-editor>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="imageDialogVisible = false">取消</el-button>
        <el-button type="primary" :loading="btnLoading" @click.native="imageHandleSure">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="putDialogTitle" :visible.sync="putDialogShow" :close-on-click-modal="false" width="550px"
      @closed="putDialogClosed">
      <div class="cance-tip">
        <div class="tip-sure">是否确认{{ putDialogTitle == '展示提示' ? '展示' : '不展示' }}该广告 ？</div>
      </div>
      <!-- <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="putDialogShow = false">取消</el-button>
        <el-button type="primary" :loading="putBtnLoading" @click.native="putDialogSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :on-close="() => {
      showViewer = false;
    }
      " :url-list="imgList" />
  </div>
</template>

<script>
import jEditor from '../systemManage/Resources/component/editor.vue'
import _api from "@/utils/request";
import environments from "@/config/url";
import { encryptByDES } from "@/utils/3DES";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import ElImgUpload from "../../components/global/components/elements/upload-element";
export default {
  name: "oldBrand-list",
  components: { ElImageViewer, ElImgUpload, jEditor },
  data() {
    return {
      // androidShowUpload:true,
      // iphoneShowUpload:true,
      uploadUrl: environments[process.env.VUE_APP_BASE_URL].uploadBaseUrl,
      imageUrl: "",
      headers: {
        ACCESS_TOKEN: localStorage.getItem("token"),
        channel: "platformPc",
        TOKEN: "",
      },
      command: "",
      rowId: "",
      imageDialogTitle: "",
      putDialogTitle: "",
      putDialogShow: false,
      imageDialogVisible: false,
      tableLoading: false,
      btnLoading: false,
      putBtnLoading: false,
      tableData: [],
      tableColumns: [
        { label: "广告名称", prop: "adName" },
        { slotName: "coverImage" },
        { slotName: "isJump" },
        { slotName: "isFounder" },
        { slotName: "isSenior" },
        { slotName: "isStaff" },
        { slotName: "sort" },
        { slotName: "operation" },
      ],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      addEditType: '',
      imgList: [],
      showViewer: false,
      sampleImage: "",
      addName: "",
      isJump: "",
      content: '',//内容
    };
  },
  created() {
    this.handleCurrentChange()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    // 富文本
    handleEditContent(html, type) {
      this.content = html
      this.$forceUpdate()
    },
    handleSettingEditContent(set, type) {
      console.log('handleSettingEditContent', this.content)
      set.html(this.content)
    },
    // 排序
    handleChange(row) {
      console.log(row);
      const params = {
        id: row.id,
        sort: row.sort,
      };
      _api.adSort(params).then((res) => {
        if (res.code === 1) {
          this.handleCurrentChange();
          this.$message.success(res.msg);
        }
      });
    },
    // 上传前调用
    beforeAvatarUpload(file) {
      this.headers.TOKEN = encryptByDES(
        encryptByDES(
          new Date().getTime().toString(),
          "63e42c2444e94c1ebca21d30d2aa39a5"
        ),
        localStorage.getItem("token") || ""
      );
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG或者PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 表格切换页面
    handleCurrentChange(val) {
      this.tableLoading = true;
      console.log("表格切换页面", val);
      const params = {
        pageNum: val,
        pageSize: this.page.pageSize,
      };
      _api.adList(params).then((res) => {
        if (res.code === 1) {
          console.log(res, '11111111');
          this.tableData = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
        }
        this.tableLoading = false;
      });
    },
    // 查看示例图大图
    seeSampleImage(row) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(row.coverImage);
    },
    // 设置展示显示配置
    switchChange(e, row, type) {
      this.saveType = type
      this.putDialogTitle = e ? "展示提示" : '不展示提示'
      this.rowId = row.id
      if (type == '00') {
        this.isFounder = e
      }
      if (type == '01') {
        this.isSenior = e
      }
      if (type == '02') {
        this.isStaff = e
      }
      this.putDialogShow = true
    },
    // 设置示例图弹框关闭
    imageHandleClose() {
      this.sampleImage = ""
      this.addName = ""
      this.imageDialogVisible = false;
    },
    // 圖片更換
    sampleHandleChange(e) {
      this.sampleImage = e;
    },
    // 添加/编辑广告
    setAddInfo(type, row) {
      this.content=''
      this.rowId = ""
      this.isJump=""
      this.addEditType = type
      if (type == "add") {
        this.imageDialogVisible = true;
        this.imageDialogTitle = "添加轮播图";
      } else {
        this.rowId = row.id
        this.getContent()
        this.imageDialogTitle = "编辑轮播图";
        this.sampleImage = row.coverImage;
        this.addName = row.adName;
        this.isJump = row.isJump ? '1' : '2';
      }
    },
    getContent(){
      _api.adContent({id:this.rowId}).then((res) => {
        if (res.code === 1) {
          console.log(res, 'xxxxx');
          this.content=res.data.content
          this.imageDialogVisible = true;
        }
      });
    },
    // 示例图保存确定
    imageHandleSure() {
      if (!this.addName) {
        this.$message.error("请输入广告名称");
        return;
      }
      if (!this.sampleImage) {
        this.$message.error("示例图片不能为空");
        return;
      }
      // 添加
      if (this.addEditType == "add") {
        const dto = {
          content: this.content,
          isJump: this.isJump === '1' ? true : false,
          adName: this.addName,
          coverImage: this.sampleImage,
        };
        this.btnLoading = true;
        _api
          .adAdd(dto)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success(res.msg || "操作成功");
              this.handleCurrentChange();
              this.imageDialogVisible = false;
            }
            this.btnLoading = false;
          })
          .catch(() => {
            this.btnLoading = false;
          });
      } else {
        const dto = {
          id: this.rowId,
          content: this.content,
          isJump: this.isJump === '1' ? true : false,
          adName: this.addName,
          coverImage: this.sampleImage,
        };
        this.btnLoading = true;
        _api
          .adEdit(dto)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success(res.msg || "操作成功");
              this.handleCurrentChange();
              this.imageDialogVisible = false;
            }
            this.btnLoading = false;
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
    // 展示/不展示弹框关闭回调
    putDialogClosed() {
      this.command = ""
        this.handleCurrentChange();
    },
    // 展示/不展示弹框确定
    putDialogSubmit() {
      let params = {
        modifyType: this.saveType,
        id: this.rowId
      }
      if (this.saveType == '00') {
        params.isFounder = this.isFounder
      }
      if (this.saveType == '01') {
        params.isSenior = this.isSenior
      }
      if (this.saveType == '02') {
        params.isStaff = this.isStaff
      }
      this.putBtnLoading = true
      _api.adSetConfig(params).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg || '操作成功')
          this.putDialogShow = false
        }
        this.putBtnLoading = false
      }).catch(() => {
        this.putBtnLoading = false
      })
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.flex {
  display: flex;
  align-items: center;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
}

.flex1 {
  flex: 1;
}

.caseFailure-caseImg {
  /deep/.el-input__inner {
    padding: 0 8px;
  }
}

.button_top {
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
}

.cance-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.case {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  width: 100%;
  padding: 14px 0;

  .class_type {
    padding: 20px;

    .tip {
      font-size: 14px;
      color: #666666;
    }

    /deep/.el-radio-group {
      .el-radio-button {
        margin-right: 20px;

        .el-radio-button__inner {
          border-radius: 4px;
          border: 1px solid #0981ff;
        }
      }
    }
  }

  .case-title {
    padding: 0 20px;
    display: flex;
    align-items: center;

    .case-title_icon {
      width: 20px;
      height: 20px;
      margin-right: 3px;
    }

    .case-title_max {
      // width: 150px;
      // height: 17px;
      font-size: 18px;
      font-family: FZLanTingHei-B-GBK;
      font-weight: 400;
      color: #333333;
      line-height: 41px;
      margin-right: 10px;
    }

    .case-title_min {
      margin-left: 5px;
      width: 500px;
      height: 13px;
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #ff687b;
      line-height: 45px;
    }
  }

  .case-img {
    margin: 20px;
    height: 280px;
    background: #f5f6fa;
    border-radius: 10px;
    display: flex;
    align-items: center;

    .upload_Add {
      width: 200px;

      // height: 200px;
      /deep/.el-upload--picture-card {
        margin: 0 30px;
      }
    }

    .upload_Class {
      position: relative;
      width: 200px;

      // height: 200px;
      .img:hover .mark {
        position: absolute;
        top: 0;
        left: 0;
        width: 148px;
        height: 148px;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }

      .img {
        position: relative;
        padding: 0;
        width: 148px;
        height: 148px;
        border-radius: 12px;
        margin: 0 auto;

        img {
          width: 148px;
          height: 148px;
        }

        .mark {
          display: none;
        }
      }

      .sort {
        width: 130px;
        height: 30px;
        margin: 10px auto;
      }

      .addName {
        width: 130px;
        height: 30px;
        margin: 10px auto;
      }
    }
  }

  .sample-image {
    /deep/.el-switch__label * {
      line-height: 1;
      font-size: 12px;
      display: inline-block;
    }

    /deep/.el-switch__label {
      position: absolute;
      display: none;
      color: #fff !important;
      font-size: 12px !important;
    }

    /*打开时文字位置设置*/
    /deep/.el-switch__label--right {
      z-index: 1;
      right: 4px;
    }

    /*关闭时文字位置设置*/
    /deep/.el-switch__label--left {
      z-index: 1;
      left: 4px;
    }

    /*显示文字*/
    /deep/.el-switch__label.is-active {
      display: block;
    }

    /*开关宽度*/
    /deep/.el-switch .el-switch__core,
    /deep/.el-switch .el-switch__label {
      width: 50px !important;
    }
  }
}
</style>
